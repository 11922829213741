import React from "react";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

const CROSSMINT_CLIENT_ID = process.env.REACT_APP_CROSSMINT_CLIENT_ID || "";

type FiatMintButtonProps = {
  totalPriceInMatic: string;
  mintAmount: string;
};

export default function FiatMintButton({
  totalPriceInMatic,
  mintAmount,
}: FiatMintButtonProps) {
  return (
    <CrossmintPayButton
      clientId={CROSSMINT_CLIENT_ID}
      environment="production"
      mintConfig={{
        type: "erc-721",
        totalPrice: totalPriceInMatic,
        _mintAmount: mintAmount,
      }}
    />
  );
}
